/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, forwardRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { CSVLink } from "react-csv";
import Query from '../graphql/query';
import moment from 'moment'
import ActivityIndicator from '../components/common/ActivityIndicator';
import {
  Box, List, ListItem, Link
} from '@material-ui/core';

import NavigationBar from 'components/common/NavigationBar';
import Sunburst from 'components/Chart/SunBurst';
import DatePicker from "react-datepicker";
import { usePopupManager } from "react-popup-manager";
import LayerPopup from "../components/common/LayerPopup";

const headers = [
  { label: 'MONTH', key: "month" },
  { label: 'Keyword 1', key: "keyword1" },
  { label: 'Keyword 2', key: "keyword2"},
  { label: 'Keyword 3', key: "keyword3"},
  { label: 'Keyword 4', key: "keyword4"},
];

const EnglishNewsNetworkMonthly = (props) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [showNewsTooltip, setShowNewsTooltip] = useState(false);
  const [CSVData, setCSVData] = useState([]);
  const [startDate, setStartDate] = useState(new Date('2004-01-01'));
  const [endDate, setEndDate] = useState(new Date(moment().subtract('1', 'M').format('YYYY-MM-DD')));
  const [targets, setTargets] = useState([]);
  const [curSunburst, setCurSunburst] = useState(moment().subtract('1', 'M'));
  const [sunburstData, setSunburstData] = useState(null);
  const [newsList, setNewsList] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [type, setType] = useState("ALL");

  const popupManager = usePopupManager();

  const {data: monthSunburstRange} = useQuery(Query.getMonthSunburstRange);
    
  useEffect(() => {
    if(monthSunburstRange){
      setStartDate(new Date(monthSunburstRange.monthSunburstRange.start))
      setEndDate(new Date(monthSunburstRange.monthSunburstRange.end))
      setTargets(monthSunburstRange.monthSunburstRange.targets)
      setCurSunburst(moment(monthSunburstRange.monthSunburstRange.end))
    }
  }, [setCurSunburst, monthSunburstRange])


  const { loading:loadingChartData, data: dataSunburst } = useQuery(Query.getMonthSunburst, {
    variables: {target: curSunburst.format('YYYY-MM-DD')}
  });

  useEffect(() => {
    if(dataSunburst){
      setSunburstData(dataSunburst.monthSunburst.dataset)
    }
  }, [dataSunburst])

  const { loading:loadNews, data:dataNews } = useQuery(Query.getMonthNews, {
    variables: {
      argument: {
        target: curSunburst.format('YYYY-MM-DD'),
        keywords: keywords.join(","),
        sentimentType: type
      }      
    }
  });

  var tmpKeywords = []

  function getParentName(node) {
    tmpKeywords.push(node.data.name)
    var np = node.parent
    while(np){
      tmpKeywords.push(np.data.name)
      np = np.parent
    }
  }

  const onSelect = (node) => {
    tmpKeywords = []
    getParentName(node)
    setKeywords(tmpKeywords)
  }

  const selectLabel = (e) =>{
    let target = e.target.value
    setType(target)
  }

  useEffect(() => {
    setNewsList([])
    if (dataNews) {
      setNewsList(dataNews.monthNews)
    }
  }, [keywords, type, dataNews])

  const changeDate = (e) => {
    const target = moment(e);
    if(target.format('YYYY-MM-DD') === curSunburst.format('YYYY-MM-DD')){
      return;
    }

    if (targets.includes(target.format('YYYY-MM-DD'))){
      setSunburstData(null)
      setCurSunburst(target);
    } else {
      popupManager.open(LayerPopup, {
        title: "현재 월은 선택이 불가능합니다.",
      });
    }
  }

  const saveSunburstCSV = () => {
    if (sunburstData){
      const newCSVData = []
      const curSunburstData = sunburstData
      for (var depth1Sunburst of curSunburstData.children){
        for (var depth2Sunburst of depth1Sunburst.children){
          for(var depth3Sunburst of depth2Sunburst.children){
            newCSVData.push({
              month: "",
              keyword1: depth1Sunburst.name,
              keyword2: depth2Sunburst.name,
              keyword3: depth3Sunburst.name
            })
          }
        }
      }
      newCSVData[0].month = sunburstData.name
      setCSVData(newCSVData)
    }
  }
    
  const CustomMonthInput = forwardRef(({ value, onClick }, ref) => (
    <button type="button" className="buttonWrap usNetwork" onClick={onClick} ref={ref}>
      <span className="icon">{` `}</span>
      <span className="date">{curSunburst.format('YYYY-MM')}</span>
    </button>
  ));

  return (
    <>
      <NavigationBar />
      <div className='container'>
        <div className='content'>
          <div className="header" style={{display:'flex'}}>
            <div style={{marginRight:'40px', width:'203px'}}>
              <label className="titleLabel">Monthly</label>
              <h2>US NEWS<br/>NETWORK</h2>
            </div>
            <div className='btnCalendar'>
              <span className="titText">Select month to view</span>
              <div className="periodForm month">
                <DatePicker
                  dateFormat='yyyy-MM'
                  placeholderText='YYYY-MM'
                  showMonthYearPicker
                  minDate={startDate}
                  maxDate={endDate}
                  onChange={changeDate}
                  customInput={<CustomMonthInput />}
                />      
              </div>
            </div>
          </div>
          <div className="section">
            <div className="contsSection">
              <div className="chartArea" style={{display:'flex', flexDirection: 'column'}}>
              {loadingChartData || !sunburstData ? (
                <ActivityIndicator wrapperId='chartIndicator'/>
              ) : (
                <>
                  <div className='chartTop'>
                    <div className={`tooltipWrap ${showTooltip ? "active" : ""}`}>
                      <div className='tooltipAlign'>
                        <span>Sunburst Chart</span>
                        <button 
                          type="button" 
                          className="btnTooltip"
                          onMouseEnter={() => {setShowTooltip(true);}}
                          onMouseLeave={() => {setShowTooltip(false);}}
                          onClick={() => {setShowTooltip(!showTooltip);}}
                        >
                          <span className="offscr">설명보기</span>
                        </button>
                        <div className="tooltip sunburst">
                          <div className="exp">
                            매월 주요 키워드 10개 및 하위 키워드의 감성분석 결과를 제공합니다.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='tooltipAlign'>
                      <CSVLink headers={headers} data={CSVData} filename="sunburstKeyword.csv">
                        <button
                          type="button"
                          className="btnDownload"
                          onClick={saveSunburstCSV}
                        />
                      </CSVLink>
                    </div>
                  </div>
                  <div style={{marginLeft:'48px', paddingTop:'16px'}}>
                    <Sunburst data={sunburstData}
                      width="600"
                      height="600"
                      count_member="size"
                      labelFunc={(node)=>node.data.name}
                      onClick={onSelect}
                      _debug={true} 
                    />
                  </div>
                </>
              )}
              </div>
              <div className="subBox">
                <div className={`tooltipWrap ${showNewsTooltip ? "active" : ""}`}>
                  <div className='tooltipAlign'>
                    <span style={{fontWeight:600}}>Related Articles</span>
                    <button 
                      type="button" 
                      className="btnTooltip"
                      onMouseEnter={() => {setShowNewsTooltip(true);}}
                      onMouseLeave={() => {setShowNewsTooltip(false);}}
                      onClick={() => {setShowNewsTooltip(!showTooltip);}}
                    >
                      <span className="offscr">설명보기</span>
                    </button>
                    <div className="tooltip articles">
                      <div className="exp">
                        주요 키워드의 관련 뉴스를 확인할 수 있습니다.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="formOption" style={{top:'200px'}}>
                  <span className="tit">View settings</span>
                  <div className="option">
                    <div className="rdGroup">
                      <span>
                        <input type="radio" id="s1" name="set" value="ALL" defaultChecked={type === "ALL"} onChange={selectLabel}/>
                        <label htmlFor="s1">All</label>
                      </span>
                      <span>
                        <input type="radio" id="s2" name="set" value="POSITIVE" defaultChecked={type === "POSITIVE"} onChange={selectLabel}/>
                        <label htmlFor="s2">Positive</label>
                      </span>
                      <span>
                        <input type="radio" id="s3" name="set" value="NEGATIVE" defaultChecked={type === "NEGATIVE"} onChange={selectLabel}/>
                        <label htmlFor="s3">Negative</label>
                      </span>
                      <span>
                        <input type="radio" id="s4" name="set" value="UNCERTAINTY" defaultChecked={type === "UNCERTAINTY"} onChange={selectLabel}/>
                        <label htmlFor="s4">Uncertainty</label>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="contsBox">
                {(loadNews || newsList.length===0) ? 
                  <ActivityIndicator  wrapperId='newsIndicator'/>
                :
                  <div className="inner">
                    <ul className="list">
                      <List dense className="news">
                        {newsList.map((value, index) => {
                          return (
                            <Link href="#" target="_blank" onClick={(e)=>{e.preventDefault(); window.open(value.url);}} key={index}>
                              <ListItem>
                                <Box className="newsInfo">
                                  <Box component="span" className="newsTitle" dangerouslySetInnerHTML={{ __html: value.title}}></Box>
                                  <Box component="span" className="newsSite">- {value.siteName}</Box>
                                  <Box component="span" className="newsContent" dangerouslySetInnerHTML={{ __html: `${value.content}.......`}}></Box>
                                </Box>
                              </ListItem>
                            </Link>
                          );
                        })}
                      </List>
                    </ul>
                  </div>
                }
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </>
  );
}; 

export default withRouter(EnglishNewsNetworkMonthly);