import React, { useState } from 'react';

const LayerPopup = (props) => {
  const [visible, setVisible] = useState(true);
  const close = () => { setVisible(false); };
  const { title, width=320, height=160, message=[], showCancel=false, handleOk=close } = props;

  return (
    <div className={`uiModal ${visible ? 'active' : ''}`} id="alertid1"> 
      <div className="modalWrap" style={{width: `${width}px`, height: `${height}px`}}>
        <div className="inner">
          <h1>{title}</h1>
          {message.map((m, i) => <p key={i}>{m}</p>)}  
        </div>
        <div className="btns">
          <button 
            type="button" 
            className="btnBase"
            onClick={handleOk}
          >
            <span>확인</span>
          </button>
        {showCancel &&
          <button 
            type="button" 
            className="btnBase"
            onClick={close}
          >
            <span>취소</span>
          </button>
        }
        </div>
      </div>
    </div>
  );
}

export default LayerPopup;