import React from 'react';

const ViewType = (props) => {
  const { defaultValue, onChangeHandler } = props;

  return (
    <>
      <span className="tit">View type</span>
      <div className="option">
        <div className="rdGroup">
          <span>
            <input 
              type="radio" 
              id="btnRadioMonthly" 
              name="viewType" 
              value="MONTH" 
              defaultChecked={defaultValue === "MONTH"} 
              onChange={(e) => { onChangeHandler(e.currentTarget.value); }}
            />
            <label htmlFor="btnRadioMonthly" className='first'>Monthly</label>
          </span>
          <span>
            <input 
              type="radio" 
              id="btnRadioweekly" 
              name="viewType" 
              value="WEEK" 
              defaultChecked={defaultValue === "WEEK"} 
              onChange={(e) => { onChangeHandler(e.currentTarget.value); }}
            />
            <label htmlFor="btnRadioweekly" className='last'>Weekly</label>
          </span>
        </div>
      </div>
    </>
  )
}

export default ViewType;