import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { SplashStyle } from "@/css/style.js";
import { Loading } from "../components/common/Loding";

const Splash = (props) => {
  const style = SplashStyle();
  const { history } = props;

  useEffect(() => {
    const checkFinterToken = () => {
      const finterToken = localStorage.getItem("finterToken");
      if (finterToken) {
        history.replace("/home");
        return;
      } else {
        history.replace("/login");
        return;
      }
    };
    setTimeout(checkFinterToken, 3000);
  }, [history]);

  return (
    // TODO: 로딩 서클로 변경
    <div className={style.SplashBox}>
      <Loading />
    </div>
  );
};

export default withRouter(Splash);
