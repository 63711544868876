import React from 'react';
import ReactApexCharts from 'react-apexcharts'

const KoreaBarChart = (props) => {
  const {data} = props
  const colors = ['#008ffb', '#00e396', '#feaf1a', '#fa969f', '#775dd0']
  const chartOptions = {
    chart: {
      colors: [...colors],
      height: "100%",
      type: 'bar',
      animations: {
        enabled: false,
      },
      toolbar:{
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top',
          hideOverflowingLabels: false,
          },    
      }
    },
    tooltip: {
      custom: function({series, seriesIndex, dataPointIndex, w}) {
        const tooltipHtml = [];

        tooltipHtml.push('<div class="tooltipBox">');
        tooltipHtml.push(`<span class="date">${data.categories[dataPointIndex]}</span>`);
        tooltipHtml.push('<div class="divider" />');
        tooltipHtml.push('<div class="homeItem">');
        tooltipHtml.push('</div>');
        tooltipHtml.push('<div class="homeItem">');
        tooltipHtml.push(`<span class="theme" style="background:${colors[seriesIndex]}">${" "}</span>`);
        tooltipHtml.push(`<span class="title">${data.data[seriesIndex].name} :  </span>`);
        tooltipHtml.push(`<span class="value" style="font-weight:700">${data.data[seriesIndex].data[dataPointIndex]}% </span>`);
        tooltipHtml.push('</div>');
        tooltipHtml.push('</div>');

        return tooltipHtml.join('');
      },
      fixed: {
        enabled: false,
        position: 'bottomLeft',
        offsetX: 100,
        offsetY: 1000,
      },
    },
    stroke: {
        width: 2
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#000000']
      },
      dropShadow: {
        enabled: false
      }
    },
    xaxis: {
        categories: data.categories,
        min: -100,
        max: 100,
        tickAmount: 4,
        position: 'bottom'
    },
  }

  return (
    <ReactApexCharts series={data.data} options={chartOptions} type="bar" style={{width: "100%", display: "flex", alignItems: "center", height: "100%"}}/>
  );
};

export default KoreaBarChart;