import React, { memo } from 'react';
import ReactApexCharts from 'react-apexcharts'
import { MaxIndex, MinIndex } from 'utils/chartNum';

const displayName = {
  kospi: "KOSPI",
  koreaTerms: "KOREA Term Spread",
  koreaCredit: "KOREA Credit Spread",
  vkospi: "VKOSPI",
  snp500: "S&P500",
  usTerms: "US Term Spread",
  usCredit: "US Credit Spread",
  vix: "VIX"
}

const HomeLineChart = (props) => {
    const { theme, reference, chartData, themeColor, referenceColor='#1F2028', setIsLoading } = props
    const min = MinIndex(chartData.spread) === -Infinity ? 0 : MinIndex(chartData.spread, true)
    const max = MaxIndex(chartData.spread) === -Infinity ? 0 : MaxIndex(chartData.spread)
    const referenceMin = MinIndex(chartData.benchmark) === -Infinity ? 0 : MinIndex(chartData.benchmark)
    const referenceMax = MaxIndex(chartData.benchmark) === -Infinity ? 0 : MaxIndex(chartData.benchmark)

    const series = [
      {
        type: 'area',
        name: displayName[theme],
        data: chartData.spread
      },
      {
        name: displayName[reference],
        data: chartData.benchmark
      }
    ]

    const chartOptions = {
      chart: {
        redrawOnWindowResize: false,
        width: '100%',
        height: '100%',
        type: 'line',
        animations: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        events: {
          mounted: () => {
            if(setIsLoading) {
              setInterval(setIsLoading(false), 10)
            }
          },
          updated: () => {
            if(setIsLoading) {
              setInterval(setIsLoading(false), 10)
            }
          }
        }
      },
      legend: {
        show: false
      },
      tooltip: {
        custom: function({series, seriesIndex, dataPointIndex, w}) {
          const tooltipHtml = [];

          tooltipHtml.push('<div class="tooltipBox">');
          tooltipHtml.push(`<span class="date">${chartData['index'][dataPointIndex]}</span>`);
          tooltipHtml.push('<div class="divider" />');
          tooltipHtml.push('<div class="homeItem">');
          tooltipHtml.push(`<span class="theme" style="background:${themeColor}">${" "}</span>`);
          tooltipHtml.push(`<span class="title">${displayName[theme]} :</span>`);
          tooltipHtml.push(`<span class="value">${chartData.spread[dataPointIndex]}</span>`);
          tooltipHtml.push('</div>');
          tooltipHtml.push('<div class="homeItem">');
          tooltipHtml.push(`<span class="reference" style="background:${referenceColor}">${" "}</span>`);
          tooltipHtml.push(`<span class="title">${displayName[reference]} :</span>`);
          tooltipHtml.push(`<span class="value">${chartData.benchmark[dataPointIndex]}</span>`);
          tooltipHtml.push('</div>');
          tooltipHtml.push('</div>');

          return tooltipHtml.join('');
        }
      },
      colors: [themeColor, referenceColor],
      stroke: {
        width: [2, 0.56],
        curve: 'straight',
      },
      xaxis: {
        type: 'datetime',
        categories: chartData.index,
        offsetY: -3,
        labels: {
          rotate: 0,
          style: {
            fontSize: '5px',
            fontWeight: 400,
            lineHeight: '6px',
            colors: '#4C4D53',
            align: 'center'
          }
        },
        tooltip: {
          enabled: false
        }
      },
      yaxis: [
        {
          seriesName: displayName[theme],
          showAlways: true,
          min: min,
          max: max,
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              fontSize: '5px',
              fontWeight: 400,
              colors: '#4C4D53',
              align: 'left'
            },
            formatter: (value) => {return Math.floor(value) }
          }
        },
        {
          seriesName: displayName[reference],
          showAlways: true,
          forceNiceScale: true,
          min: referenceMin,
          max: referenceMax,
          opposite: true,
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: '#4C4D53',
              align: 'right',
              fontSize: '5px',
              fontWeight: 400,
            },
            formatter: (value) => {return Math.floor(value) }
          },
          title: {
            text: displayName[reference],
            rotate: 0,
            offsetX: -52,
            offsetY: -200,
            style: {
              color: '#4C4D53',
            }
          },
        }
      ]
    }

    return (
      <div style={{marginBottom: '20px'}}>
        <div className='headBox'>
          <h3>{displayName[theme]} / {displayName[reference]}</h3>
          <div className='legend'>
            <span className={`leg1 ${theme}`}>{displayName[theme]}</span>
            <span className={`leg2 ${referenceColor === '#1F2028' ? '' : reference}`}>{displayName[reference]}</span>
          </div>
        </div>
        <ReactApexCharts series={series} options={chartOptions} type="line" width="100%" height="100%"/>
      </div>
    );
};

export default memo(
  HomeLineChart,
  (prev, next) => {
    return prev.chartData === next.chartData
  },
);