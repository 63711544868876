export const MinIndex = (num_list, isflaot=false) => {
    let digit
    var min_int = Math.floor(Math.min(...num_list))
    if (isflaot)
        digit = min_int.toString().length - 2
    else
        digit = Math.max(min_int.toString().length - 2, 1)
    return Math.floor(min_int / Math.pow(10, digit)) * Math.pow(10, digit)
};

export const MaxIndex = (num_list, isflaot=false) => {
    let digit
    var man_int = Math.ceil(Math.max(...num_list))
    if(man_int < 10) {
      return man_int;
    }
    if (isflaot)
        digit = man_int.toString().length - 2
    else
        digit = Math.max(man_int.toString().length - 2, 1)
    return Math.ceil(man_int / Math.pow(10, digit)) * Math.pow(10, digit)
};

export const AbsoluteIndex = (num_list, target, isflaot=false) => {
    var abMax = MaxIndex(num_list, isflaot) - target
    var abMin = target - MinIndex(num_list, isflaot)
    return Math.max(abMax, abMin)
};
  