import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { useQuery } from '@apollo/client';
import Query from '../graphql/query';
import moment from 'moment';
import ActivityIndicator from '../components/common/ActivityIndicator';
import NavigationBar from 'components/common/NavigationBar';
import ViewType from 'components/common/ViewType';
import ViewRange from 'components/common/ViewRange';
import IndexLineChart from 'components/Chart/Line/IndexLineChart';
import CustomGaugeChart from '../utils/customGaugeChart';
import { dateFormats } from '../utils/constants';

const EnglishNewsIndex = () => {
  const [csvData, setCSVData] = useState([]);
  const [csvFileName, setCSVFileName] = useState("");
  const [viewType, setViewType] = useState("MONTH");
  const [startDate, setStartDate] = useState(moment().subtract(1, 'Y').format(dateFormats.dash_month));
  const [endDate, setEndDate] = useState(moment().format(dateFormats.dash_month));
  const [currentMonth, setCurrentMonth] = useState(moment().format(dateFormats.dash_month));
  const [usIndex, setUsIndex] = useState();
  const [usSignal, setUsSignal] = useState();

  const viewTypeChangeHandler = (value) =>  {
    const dateType = value === 'MONTH'? dateFormats.dash_month : dateFormats.dash_day;
    setStartDate(moment(startDate).format(dateType));
    setEndDate(moment().format(dateType));
    setViewType(value)
  };

  const viewRangeHandler = (startDate, endDate) => {
    const dateType = viewType === 'MONTH'? dateFormats.dash_month : dateFormats.dash_day;
    setStartDate(moment(startDate).format(dateType));
    setEndDate(moment(endDate).format(dateType));
  }

  const { loading:loadingIndex, data:chartData, refetch:refreshUsIndex } = useQuery(Query.getUsIndex, {
    variables: {start: startDate, end: endDate, interval: viewType}
  });

  useEffect(() => {
    refreshUsIndex();
  }, [startDate, endDate, viewType, refreshUsIndex])

  useEffect(() => {
    if(chartData){
      setUsIndex(chartData.usIndex)
    }
  }, [chartData]);

  useEffect(() => {  
    if(chartData) {
      const data = chartData.usIndex;

      // react-csv에서 사용가능한 형태로 재가공한다
      const csvData = data.date.map((date, i) => {
        return {
          index: date,
          sp500: data.index[i],
          ews: data.ews[i]
        }
      })

      setCSVData(csvData)
      setCSVFileName("USIndexCSV.csv")
    }
  }, [chartData, viewType]);

  const { loading:loadingSummary, refetch:refreshSummary } = useQuery(Query.getUsSummary, {
    variables: {date: currentMonth}
  });

  useEffect(() => {
    setUsSignal();
    refreshSummary({date: currentMonth})
    .then((data) => {
      setUsSignal(data.data.usSignal)
    })
  }, [refreshSummary, currentMonth])

  return (
    <>
      <NavigationBar />
      <div className="container">
        <div className="content">
          <div className="header">
            <h2>US NEWS<br/>INDEX</h2>
            <div className="formOption">
              <ViewType 
                defaultValue={viewType}
                onChangeHandler={viewTypeChangeHandler}
              />
              <ViewRange
                viewType={viewType}
                onChangeHandler={viewRangeHandler}
                title="eni"
              />
            </div>
          </div>
        {(loadingIndex || loadingSummary) ? (
          <ActivityIndicator />
        ) : (
          <div className='newsIndexChart'>
            <div className='chartHeader'>
              <div className='legend'>
                <span className='leg1'>S&amp;P500</span>
                <span className='leg2'>S&amp;P500 EWS</span>
              </div>
              <CSVLink data={csvData} filename={csvFileName}>
                <button className='btnDownload'/>
              </CSVLink>
            </div>
            {usIndex &&
              <IndexLineChart name='S&P500' data={usIndex} setTarget={setCurrentMonth} viewType={viewType}/>
            }
          {usSignal &&
            <div className='summaryWrap'>
              <div className='gaugeChartWrap'>
                <span className='title'>Early Warning Signal</span>
                <div className='gaugeChart border'>
                  <CustomGaugeChart signal={usSignal.gauge} category='eni' />
                </div>
              </div>
              <div className='summary border'>
              {usSignal.summery.length > 0 ? (
                usSignal.summery.map((summary, index) => {
                  return (
                    <div key={index} className='summaryItem'>
                      <div className='title'>{summary.title} </div>
                      <div className='contents'>{summary.content.map((c, i) => <span key={i}>{c}<br/></span>)}</div>
                    </div>
                  );
                })
              ) : (
                <div className='summaryItem'>
                  <div className='contents'>데이터가 없습니다.</div>
                </div>
              )}
              </div>
            </div>
          }
          </div>
        )}
          </div>
      </div>
    </>
  );
}; 

export default withRouter(EnglishNewsIndex);