import React, { useRef, useEffect, useState } from 'react';
import { arc } from 'd3-shape';
import { select } from 'd3-selection';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { dateFormats, minDates } from './constants';

const CustomGaugeChart = (props) => {
  const { width, height, signal, category, setDate, forHome=false } = props;
  const startAngle = -Math.PI / 2;
  const endAngle = Math.PI / 2;
  const gapAngle = Math.PI / 3;

  const doughnutRef = useRef();

  const calculateIndex = (percent) => {
    if(percent <= 0.3333) return 0;
    if(percent <= 0.6667) return 1;
    return 2;
  }

  const status = [
    {
      className: 'normal',
      text: '정상',
      textPosition: {
        x: 38,
        y: 72
      },
      fillColor: '#61B08E',
      borderColor: '#1E8E5E'
    },
    {
      className: 'caution',
      text: '주의',
      textPosition: {
        x: 90,
        y: 40
      },
      fillColor: '#F7D66B',
      borderColor: '#F4C42C'
    },
    {
      className: 'danger',
      text: '위험',
      textPosition: {
        x: 142,
        y: 72
      },
      fillColor: '#F76B79',
      borderColor: '#F42C3F'
    },
  ];

  const [index, setIndex] = useState(0);
  const [canGoNext, setCanGoNext] = useState(false);
  const [canGoPrev, setCanGoPrev] = useState(true);

  useEffect(() => {
    const index = calculateIndex(signal?.signal);
    setIndex(index);

    const today = moment().format(dateFormats.dash_month);
    const minDate = moment(minDates[category]?.MONTH).format(dateFormats.dash_month);
    setCanGoNext(signal?.time < today);
    setCanGoPrev(signal?.time > minDate);
  }, [signal, category])

  useEffect(() => {
    const doughnut = arc()
      .innerRadius(29)
      .outerRadius(87)
      .startAngle(startAngle)
      .endAngle(endAngle);

    select(doughnutRef.current)
      .append('path')
      .attr('transform', 'translate(100, 100)')
      .attr('d', doughnut)
      .style('fill', '#F2F2F2')
      .style('stroke', '#D9D9D9')
      .style('stroke-width', 1);
      
    select(doughnutRef.current)
      .append('text')
      .text('정상')
      .attr('x', 12)
      .attr('y', 116)
      .attr('font-family', 'Pretendard')
      .attr('font-size', '9px')
      .attr('fill', '#1F2028')
      .attr('text-anchor', 'left');

    select(doughnutRef.current)
      .append('text')
      .text('위험')
      .attr('x', 172)
      .attr('y', 116)
      .attr('font-family', 'Pretendard')
      .attr('font-size', '9px')
      .attr('fill', '#1F2028')
      .attr('text-anchor', 'right');

    const currentStartAngle = startAngle + (index * gapAngle);

    const indicator = arc()
      .innerRadius(29)
      .outerRadius(87)
      .startAngle(currentStartAngle)
      .endAngle(currentStartAngle + gapAngle);

    select(doughnutRef.current)
      .append('path')
      .attr('transform', 'translate(100, 100)')
      .attr('d', indicator)
      .style('fill', status[index].fillColor)
      .style('stroke', status[index].borderColor)
      .style('stroke-width', 1);

    select(doughnutRef.current)
      .append('text')
      .text(status[index].text)
      .attr('x', status[index].textPosition.x)
      .attr('y', status[index].textPosition.y)
      .attr('font-family', 'Pretendard')
      .attr('font-size', '12px')
      .attr('font-weight', 800)
      .attr('fill', '#FFF')
      .attr('text-anchor', 'left');
  }, [index, startAngle, endAngle, gapAngle, status]);

  const goNext = () => {
    const targetDate = moment(signal?.time).add(1, 'M').format(dateFormats.dash_month);
    setDate(targetDate);
  }

  const goPrev = () => {
    const targetDate = moment(signal?.time).subtract(1, 'M').format(dateFormats.dash_month);
    setDate(targetDate)
  }

  return (
    <div className='gauge' style={{width: `${width}px`, height: `${height}px`}}>
      <div className='dateWrap'>
      {forHome ? (
      <>
        <button 
          className={`prev ${canGoPrev ? 'on' : ''}`}
          onClick={goPrev}
          disabled={!setDate && !canGoPrev}
        />
        <DatePicker
          dateFormat={dateFormats.dash_month}
          placeholderText={dateFormats.dash_month}
          showMonthYearPicker
          minDate={new Date(minDates[category].MONTH)}
          maxDate={new Date()}
          onChange={(date) => {
            const value = moment(date).format(dateFormats.dash_month);
            setDate(value);
          }}
          customInput={<span style={{cursor: 'pointer'}}>{signal?.time}</span>}
        />
        <button 
          className={`next ${canGoNext ? 'on' : ''}`}
          onClick={goNext}
          disabled={!setDate && !canGoNext}
        />
      </>
      ) : (
        <span>{signal?.time}</span>
      )} 
      </div>
      <svg width={200} height={140}>
        <g ref={doughnutRef} className='doughnut'></g>
      </svg>
      <span className={`needle ${status[index].className}`}></span>
    </div>
  )

}

export default CustomGaugeChart;