import Fragment from "./fragment";
import { gql } from "@apollo/client";

export default class Query {
  static getKoreaSignal = gql`
    query getKoreaSignal($date: Date!) {
      koreaSignal(date: $date) {
        gauge {
          ...gauge
        }
      }
    }
    ${Fragment.gauge}
  `;

  static getUsSignal = gql`
    query getUsSignal($date: Date!) {
      usSignal(date: $date) {
        gauge {
          ...gauge
        }
      }
    }
    ${Fragment.gauge}
  `;

  static getHomeSpread = gql`
    query getHomeSpread($start: Date!, $end: Date!) {
      spread(start: $start, end: $end) {
        koreaTerms {
          ...SpreadData
        }
        koreaCredit {
          ...SpreadData
        }
        vkospi {
          ...SpreadData
        }
        usTerms {
          ...SpreadData
        }
        usCredit {
          ...SpreadData
        }
        vix {
          ...SpreadData
        }
      }
    }
    ${Fragment.spreadData}
  `;

  static getKoreaIndex = gql`
    query getKoreaIndex(
      $start: Date!
      $end: Date!
      $interval: IndexIntervalType!
    ) {
      koreaIndex(start: $start, end: $end, interval: $interval) {
        date
        index
        smoothing
        ews
        annotations {
          ...annotation
        }
      }
    }
    ${Fragment.annotation}
  `;

  static getKoreaSummary = gql`
    query getKoreaSummery($date: Date!) {
      koreaSignal(date: $date) {
        ...Signal
      }
    }
    ${Fragment.Signal}
  `;

  static getUsIndex = gql`
    query getUsIndex(
      $start: Date!
      $end: Date!
      $interval: IndexIntervalType!
    ) {
      usIndex(start: $start, end: $end, interval: $interval) {
        date
        index
        smoothing
        ews
        annotations {
          ...annotation
        }
      }
    }
    ${Fragment.annotation}
  `;

  static getUsSummary = gql`
    query getUsSummery($date: Date!) {
      usSignal(date: $date) {
        ...Signal
      }
    }
    ${Fragment.Signal}
  `;

  static getRadarLegend = gql`
    query getRadarLegend {
      radarLegend {
        indexList
        targets
        start
        end
      }
    }
  `;

  static getRadarData = gql`
    query getRadarData($indexList: [String]!) {
      radarChartDataset(indexList: $indexList) {
        ...chartData
      }
      subplotChart(indexList: $indexList) {
        ...subplotChartDataSet
      }
    }
    ${Fragment.subplotChartDataSet}
  `;

  static getMonthSunburstRange = gql`
    query getMonthSunburstRange {
      monthSunburstRange {
        start
        end
        targets
      }
    }
  `;

  static getMonthSunburst = gql`
    query getMonthSunburst($target: Date!) {
      monthSunburst(target: $target) {
        dataset {
          ...sunburstRecursive
        }
      }
    }
    ${Fragment.sunburstRecursive}
  `;

  static getMonthNews = gql`
    query getMonthNews($argument: MonthNewsArgument!) {
      monthNews(argument: $argument) {
        ...news
      }
    }
    ${Fragment.news}
  `;

  static getWeekSunburstRange = gql`
    query getWeekSunburstRange {
      weekSunburstRange {
        start
        end
        targets {
          start
          end
        }
      }
    }
  `;

  static getWeekSunburst = gql`
    query getWeekSunburst($start: Date!, $end: Date!) {
      weekSunburst(start: $start, end: $end) {
        dataset {
          ...sunburstRecursive
        }
      }
    }
    ${Fragment.sunburstRecursive}
  `;

  static getWeekNews = gql`
    query getWeekNews($argument: WeekNewsArgument!) {
      weekNews(argument: $argument) {
        ...news
      }
    }
    ${Fragment.news}
  `;
}
