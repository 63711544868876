export default class Fragment {
  static gauge = `
    fragment gauge on Gauge {
      time
      signal
    }`;
  
  static summery = `
    fragment summery on Summery {
      title
      content
    }`;

  static Signal = `
    fragment Signal on Signal {
      gauge {
        ...gauge
      }
      summery {
        ...summery
      }
    }
    ${this.gauge}
    ${this.summery}`;
  
  static spreadDataMeta = `
    fragment SpreadDataMeta on SpreadDataMeta {
      start
      end
      length
    }`;
  
  static spreadData = `
    fragment SpreadData on SpreadData {
      meta {
        ...SpreadDataMeta
      }
      index
      benchmark
      spread
    }
    ${this.spreadDataMeta}`;

  static news = `
  fragment news on News{
    id
    url
    siteName
    title
    content
    documentDate
  }`

  static debug = `
  fragment _debug on DjangoDebug{
      sql{
          vendor
          alias
          sql
          duration
          rawSql
          params
          startTime
          stopTime
          isSlow
          isSelect
          transId
          transStatus
          isoLevel
          encoding
      }
  }`
  
  static SunBurstData = `
  fragment SunBurstData on SunBurstData {
    key
    name
    size
    color
  }
  `

  static sunburstRecursive = `
  fragment sunburstRecursive on SunBurstData {
    ...SunBurstData
    children {
      ...SunBurstData
      children {
        ...SunBurstData
        children {
          ...SunBurstData
        }
      }
    }
  }
  ${this.SunBurstData}
  `

  static chartData = ` 
  fragment chartData on RadarChartData{
      name
      data
  }`
  static subplotChartData = `
  fragment subplotChartData on SubplotChartData{
      categories
      data{
          ...chartData
      }
  }
  ${this.chartData}`

  static subplotChartDataSet = `
  fragment subplotChartDataSet on SubplotChartDataSet{
      ecEconomy{
          ...subplotChartData
      }
      ecFinance{
          ...subplotChartData
      }
      ecPolicy{
          ...subplotChartData
      }
      coOperation{
          ...subplotChartData
      }
      coBusiness{
          ...subplotChartData
      }
      coProfit{
          ...subplotChartData
      }
      coConditions{
          ...subplotChartData
      }
      coOther{
          ...subplotChartData
      }
      ifEconomy{
          ...subplotChartData
      }
      ifFinance{
          ...subplotChartData
      }
      ifPolicy{
          ...subplotChartData
      }
      ifOther{
          ...subplotChartData
      }
      otherOther{
          ...subplotChartData
      }
      etcEtc{
          ...subplotChartData
      }
  }
  ${this.subplotChartData}
  `
  static annotation = `
    fragment annotation on Annotation {
      x
      x2
    }
  `;
}