import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom'; 
import { useQuery } from '@apollo/client';
import Query from '../graphql/query';
import NavigationBar from 'components/common/NavigationBar';
import ViewRange from 'components/common/ViewRange';
import HomeLineChart from '../components/Chart/Line/HomeLineChart';
import CustomGaugeChart from '../utils/customGaugeChart';
import moment from 'moment';
import ActivityIndicator from '../components/common/ActivityIndicator';
import { dateFormats } from '../utils/constants';

const Home = () => {
  const [tempDate, setTempDate] = useState();
  const [date, setDate] = useState({
    start: moment().subtract('1', 'Y').format(dateFormats.dash_month),
    end: moment().format(dateFormats.dash_month)
  })
  const [koreaSignalDate, setKoreaSignalDate] = useState(moment().format(dateFormats.dash_month));
  const [usSignalDate, setUsSignalDate] = useState(moment().format(dateFormats.dash_month));
  const [koreaSignal, setKoreaSignal] = useState();
  const [usSignal, setUsSignal] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [homeSpread, setHomeSpread] = useState();

  const { data:koreaSignalData } = useQuery(Query.getKoreaSignal, {
    variables: {date: koreaSignalDate}
  });
  useEffect(() => {
    if(koreaSignalData) {
      setKoreaSignal(koreaSignalData.koreaSignal.gauge)
    }
  }, [koreaSignalData]);

  const { data:usSignalData } = useQuery(Query.getUsSignal, {
    variables: {date: usSignalDate}
  });

  useEffect(() => {
    if(usSignalData) {
      setUsSignal(usSignalData.usSignal.gauge)
    }
  }, [usSignalData]);
  
  const { data:spreadData } = useQuery(Query.getHomeSpread, {
    variables: { ...date },
  });

  const viewRangeHandler = (startDate, endDate) =>  {
    if(date.start === startDate && date.end === endDate) return;
    
    setIsLoading(true);
    setHomeSpread();
    setTempDate({
      start: startDate,
      end: endDate
    });
  };

  useEffect(() => {
    if(!isLoading || !tempDate) return;
    setDate(tempDate);
  }, [isLoading, tempDate]);

  useEffect(() => {
    if(spreadData) {
      setHomeSpread(spreadData.spread);
    }
  }, [spreadData])

  return (
    <>
      <NavigationBar />
      <div className="container">
        <div className="content">
          <div className="header">
            <h2>EARLY WARNING<br/>SIGNAL</h2>
            <div className="formOption">
              <ViewRange
                viewType={'MONTH'}
                onChangeHandler={viewRangeHandler}
                title="home"
              />
            </div>
          </div>
        {isLoading &&
          <ActivityIndicator position={'absolute'} />
        }
          <div className='homeChart'>
            <div className='chartItem'>
              <div className='gaugeChartWrap'>
                <span className='title'>KOR</span>
                <CustomGaugeChart signal={koreaSignal} category='kni' setDate={setKoreaSignalDate} forHome={true} />
              </div>
              <div className='lineChartWrap'>
              {homeSpread &&
                <>
                  <HomeLineChart 
                    theme='koreaTerms'
                    reference='kospi'
                    themeColor='#FEEAEC'
                    chartData={homeSpread.koreaTerms}
                  />
                  <HomeLineChart 
                    theme='koreaCredit'
                    reference='kospi'
                    themeColor='#FEF2EB'
                    chartData={homeSpread.koreaCredit}
                  />
                  <HomeLineChart 
                    theme='vkospi'
                    reference='kospi'
                    themeColor='#FEF9EA'
                    chartData={homeSpread.vkospi}
                  />
                </>
              }
              </div>
            </div>
            <div className='delimiter'/>
            <div className='chartItem'>
              <div className='gaugeChartWrap'>
                <span className='title'>US</span>
                <CustomGaugeChart signal={usSignal} category='eni' setDate={setUsSignalDate} forHome={true} />
              </div>
              <div className='lineChartWrap'>
              {homeSpread &&
              <>
                <HomeLineChart 
                  theme='usTerms'
                  reference='snp500'
                  themeColor='#E8F4EF'
                  chartData={homeSpread.usTerms}
                />
                <HomeLineChart 
                  theme='usCredit'
                  reference='snp500'
                  themeColor='#E9F1F7'
                  chartData={homeSpread.usCredit}
                />
                <HomeLineChart 
                  theme='vix'
                  reference='snp500'
                  themeColor='#EAEEFE'
                  chartData={homeSpread.vix}
                  setIsLoading={setIsLoading}
                />
              </>
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}; 

export default withRouter(Home);