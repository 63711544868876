import React from "react";
import ReactDOM from "react-dom";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  concat,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import routes from "route";
import { pdfjs } from "react-pdf";
import { PopupProvider } from "react-popup-manager";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const BASE_URL = "https://api.ews.quantit.io";
const httpLink = createUploadLink({ uri: `${BASE_URL}/graphql` });
const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    let finterToken = window.localStorage.getItem("finterToken") ?? undefined;
    let jwt = window.localStorage.getItem("jwt") ? `JWT ${window.localStorage.getItem("jwt")}` : undefined;
    let authorization = finterToken ? finterToken : jwt;
    return {
      headers: {
        ...headers,
        ...(authorization && { Authorization: authorization }),
      },
    };
  });

  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});

const App = () => {
  const history = createBrowserHistory();

  return (
    <Router history={history}>
      <Switch>
        {routes.map((r) => (
          <Route key={r.path} path={r.path} component={r.component} exact />
        ))}
      </Switch>
    </Router>
  );
};

ReactDOM.render(
  <ApolloProvider client={client}>
    <PopupProvider>
      <App />
    </PopupProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
