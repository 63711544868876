import Splash from 'pages/Splash';
import Login from 'pages/Login';
import Home from 'pages/Home';
import KoreanNewsIndex from 'pages/KoreanNewsIndex';
import KoreanNewsNetwork from 'pages/KoreanNewsNetwork';
import EnglishNewsIndex from 'pages/EnglishNewsIndex';
import EnglishNewsNetworkMonthly from 'pages/EnglishNewsNetworkMonthly';
import EnglishNewsNetworkWeekly from 'pages/EnglishNewsNetworkWeekly';

const routes = [
    {
        name: 'Korean news Index',
        path: '/korea/ni',
        component: KoreanNewsIndex,
    },
    {
        name: 'Korean news network',
        path: '/korea/nn',
        component: KoreanNewsNetwork,
    },
    {
        name: 'US news Index',
        path: '/us/ni',
        component: EnglishNewsIndex,
    },
    {
        name: 'US news network (monthly)',
        path: `/us/nnm`,
        component: EnglishNewsNetworkMonthly,
    },
    {
        name: 'US news network (weekly)',
        path: `/us/nnw`,
        component: EnglishNewsNetworkWeekly,
    },
    {
        name: 'ews home',
        path: '/home',
        component: Home,
    },
    {
        name: 'login',
        path: '/login',
        component: Login,
    },
    {
        name: 'splash',
        path: '/',
        component: Splash,
    },
];

export default routes;