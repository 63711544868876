import React from "react";
import Lottie from "lottie-react";
import animationData from "../../lotties/loading-animation.json";
import styles from './Loading.module.css';

export function Loading() {
  return (
    <div className={styles.loading_container}>
      <div className={styles.lottie_container}>
        <Lottie animationData={animationData} loop={true} />
      </div>
      <h1>LOADING...</h1>
    </div>
  );
}
