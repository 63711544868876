import React from "react";
import DatePicker from "rsuite/DatePicker";

import "rsuite/dist/rsuite.min.css";
import "./WeekPicker.css";
import moment from "moment";

const WeekPicker = (props) => {
  const { curSunburst, setCurSunburst, setSunburstData, startDate, endDate } =
    props;


  const selectedDate = () => {
    if (Object.keys(curSunburst).length === 0) {
      return new Date();
    } else {
      return new Date(curSunburst.start);
    }
  };

  const renderValue = () => {
    if (Object.keys(curSunburst).length === 0) {
      return "Select Week";
    } else {
      return `${curSunburst.start} ~ ${curSunburst.end}`;
    }
  }

  const onSelect = (date) => {
    const dateFrom = moment(date).startOf("isoWeek");
    const dateTo = moment(date).endOf("isoWeek");
    if (dateFrom.toDate() <= startDate || dateTo.toDate() >= endDate) {
      return;
    }

    const start = dateFrom.format("YYYY-MM-DD");
    const end = dateTo.format("YYYY-MM-DD");

    if (curSunburst.start !== start || curSunburst.end !== end) {
      setSunburstData(null);
      setCurSunburst({
        start: dateFrom.format("YYYY-MM-DD"),
        end: dateTo.format("YYYY-MM-DD"),
      });
    }
  };

  const shouldDisableDate = (date) => {
    if (date <= startDate || date >= endDate) {
      return true;
    }else{
      return false;
    }
  }

  return (
    <div className="week-picker">
      <DatePicker
        placeholder={"Select Week"}
        isoWeek
        showWeekNumbers
        editable={false}
        value={selectedDate()}
        onSelect={onSelect}
        shouldDisableDate={shouldDisableDate}
        renderValue={renderValue}
      />
    </div>
  );
};

export default WeekPicker;
