import React, { useEffect, useState, forwardRef } from 'react';
import { usePopupManager } from "react-popup-manager";
import DatePicker from "react-datepicker";
import moment from "moment";
import LayerPopup from "./LayerPopup";
import { ViewRangeOptions, minDates, dateFormats } from '../../utils/constants';

const ViewRange = (props) => {
  const { viewType, onChangeHandler, title } = props;
  const popupManager = usePopupManager();
  
  const [initialDate, setInitialDate] = useState(minDates.home);
  const defaultDate = dateFormats.dot_month;

  const [options, setOptions] = useState(ViewRangeOptions['MONTH'].options);
  const [selectedValue, setSelectedValue] = useState('1y');

  const [startDate, setStartDate] = useState(defaultDate);
  const [endDate, setEndDate] = useState(defaultDate);

  useEffect(() => {
    setOptions(ViewRangeOptions[viewType].options);
    // 시작, 종료날짜가 모두 선택되었으면 viewType을 변경해도 selectedValue에 영향이 없어야한다.
    if(defaultDate !== startDate && defaultDate !== endDate) {
      setSelectedValue()
    } else{
      setSelectedValue(ViewRangeOptions[viewType].default)
    }
    setInitialDate(minDates[title][viewType])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewType, title])

  const CustomStartDateInput = forwardRef(({ value, onClick }, ref) => (
    <button type="button" className="buttonWrap" onClick={onClick} ref={ref}>
      <span className="icon">{` `}</span>
      <span className="date">{startDate}</span>
    </button>
  ));

  const CustomEndDateInput = forwardRef(({ value, onClick }, ref) => (
    <button type="button" className="buttonWrap" onClick={onClick} ref={ref}>
      <span className="icon">{` `}</span>
      <span className="date">{endDate}</span>
    </button>
  ));

  useEffect(() => {
    if(defaultDate === startDate || defaultDate === endDate) return;

    onChangeHandler(startDate, endDate);
  }, [defaultDate, startDate, endDate, onChangeHandler]);

  return (
    <>
      <span className="tit">View range</span>
      <div className="option">
        <div className="rdGroup">
        {options.map((o, index) => {
          return (
            <span key={index}>
              <input 
                type="radio" 
                id={`viewRange_${o.name}`} 
                name="date" 
                value={o.value}
                checked={o.value === selectedValue}
                onChange={(e) => {
                  // 기존에 datepicker를 통해 입력된 데이터가 있으면 초기화를 해준다
                  setStartDate(defaultDate);
                  setEndDate(defaultDate);

                  setSelectedValue(e.currentTarget.value);
                  const dateType = viewType === 'MONTH' ? dateFormats.dash_month : dateFormats.dash_day
                  if('all' === e.currentTarget.value) {
                    onChangeHandler(initialDate, moment().format(dateType));
                  } else {
                    onChangeHandler(moment().subtract(o.name.charAt(0), o.name.charAt(1)).format(dateType), moment().format(dateType));
                  }
                }}
              />
              <label htmlFor={`viewRange_${o.name}`}>{o.name}</label>
            </span>
          );
        })}
        </div>
        <div className={`formPeriod ${viewType === 'MONTH' ? 'month' : 'week'}`}>
          <DatePicker
            dateFormat='yyyy-MM'
            placeholderText='YYYY-MM'
            showMonthYearPicker={viewType === 'MONTH'} 
            minDate={new Date(initialDate)}
            maxDate={new Date()}
            onChange={(date) => {
              const format = viewType === 'MONTH' ? dateFormats.dash_month : dateFormats.dash_day;
              const value = moment(date).format(format);
              
              if(endDate !== defaultDate) {
                if(value >= endDate) {
                  popupManager.open(LayerPopup, {
                    title: "시작일은 종료일보다 작아야 합니다.",
                  });
                  return;
                }
              }

              setStartDate(value);
              setSelectedValue();
            }}
            customInput={<CustomStartDateInput value={startDate}/>}
          />
          <DatePicker
            dateFormat='yyyy-MM'
            placeholderText='YYYY-MM'
            showMonthYearPicker={viewType === 'MONTH'} 
            minDate={new Date(initialDate)}
            maxDate={new Date()}
            onChange={(date) => {
              const format = viewType === 'MONTH' ? dateFormats.dash_month : dateFormats.dash_day;
              const value = moment(date).format(format);
              
              if(startDate !== defaultDate) {
                if(value <= startDate) {
                  popupManager.open(LayerPopup, {
                    title: "종료일은 시작일보다 커야 합니다",
                  });
                  return;
                }
              }

              setEndDate(value);
              setSelectedValue();
            }}
            customInput={<CustomEndDateInput />}
          />
        </div>
      </div>
    </>
  )
}

export default ViewRange;