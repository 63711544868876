export const ViewRangeOptions = {
  "MONTH": {
    default: '1y',
    options: [
      {
        "name": "All",
        "value": "all",
        "default": false
      },
      {
        "name": "3Y",
        "value": "3y",
        "default": false
      },
      {
        "name": "1Y",
        "value": "1y",
        "default": true
      },
      {
        "name": "6M",
        "value": "6m",
        "default": false
      },
    ],
  },
  "WEEK": {
    default: '6m',
    options: [
      {
        "name": "All",
        "value": "all",
        "default": false
      },
      {
        "name": "1Y",
        "value": "1y",
        "default": false
      },
      {
        "name": "6M",
        "value": "6m",
        "default": true
      },
      {
        "name": "3M",
        "value": "3m",
        "default": false
      },
    ]
  }
};

export const minDates = {
  "kni": {
    "MONTH": '2008-03-01',
    "WEEK": '2008-03-01'
  },
  "eni": {
    "MONTH": '2008-03-01',
    "WEEK": '2008-02-10'
  },
  "home": {
    "MONTH": '2005-02-01'
  }
}

export const dateFormats = {
  "dot_month": 'YYYY.MM',
  "dot_day": 'YYYY.MM.DD',
  "dash_month": 'YYYY-MM',
  "dash_day": 'YYYY-MM-DD',
}