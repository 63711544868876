import { makeStyles } from '@material-ui/core/styles';
import quantit_symbol from '@/images/quantit_symbol.png';

// Common Constant
const drawerWidth = 240;
const headerHeight = 75
const footerHeight = 105
const flexCenter = {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center"
}
export const KeyColor = "#FF6633"
export const KeyColor2 = "#f89B6C"
export const KeyColor3 = "#f8B484"

export const SplashStyle = makeStyles((theme) => ({
    SplashBox:{
        position: "relative",
        width: "100vw", 
        height: "100vh", 
        display: "flex", 
        justifyContent:"center", 
        alignItems: "center",
        "& img": {
            position: "absolute",
            height: "20vh",
            width: "auto",
            opacity: 0
        }
    },
    hanwha: {
        animation: `$fadeindout 2000ms ${theme.transitions.easing.easeInOut}`
    },
    handa: {
        animation: `$fadeindout 1500ms ${theme.transitions.easing.easeInOut} 1500ms`
    },
    quantit: {
        animation: `$fadeindout 1500ms ${theme.transitions.easing.easeInOut} 1500ms`
    },
    "@keyframes fadeindout": {
        "from": {
            opacity: 0
        },
        "50%": {
            opacity: 1
        },
        "to": {
            opacity: 0
        }
    }
}))

// Header
export const HeaderStyle = makeStyles((theme) => ({
    header:{
        display: 'flex'
    },
    appBar: {
        zIndex: `${theme.zIndex.drawer + 1} !important`,
        background: theme.palette.common.white,
        ...flexCenter
    },
    toolbar:{
        [theme.breakpoints.up("md")]: {
            width: "calc(100% - 48px)",
            flexDirection: "row",
            justifyContent: "flex-start !important",
            alignItems: "center !important",
        },
        width: "90%",
        flexDirection: "column",
        alignItems: "flex-start !important",
        justifyContent: "center",
        "& h6":{
            [theme.breakpoints.up("md")]: {
                fontSize: "1.25rem",
                width: "auto"
            },
            fontSize: 14,
            width: "50vw",
            textOverflow: "unset !important",
            overflow: "unset !important",
            whiteSpace: "normal !important"
        }
    },
    logo: {
        [theme.breakpoints.up("md")]: {
            width: "40vw",
        },
        maxHeight: "60%",
        maxWidth: "170px",
        width: "20vw",
        marginRight: "2vw"
    },
    userMenu:{
        [theme.breakpoints.up("md")]: {
            top: 18,
            flexDirection: "row",
            alignItems: "center",
            paddingRight: 24
        },
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "center",
        paddingRight:"5vw",
        position: "absolute",
        top: 0,
        right: 0
    },
    account: {
        display: 'flex',
        flexDirection: "column",
        [theme.breakpoints.up("md")]: {
            marginRight: "15px"
        },
    },
    accountInfo: {
        fontSize: "10px",
        [theme.breakpoints.up("md")]: {
            fontSize: "14px",
        },
        color: "#202020",
        fontWeight: "bold"
    },
    button:{
        background: theme.palette.common.white,
        fontWeight: "bold !important",
        border: `2px solid ${KeyColor} !important`,
        fontSize: "10px !important" ,
        padding: "3px 5px !important",
        "&:hover span":{
            transition: "all 0.3s ease",
            color: `${KeyColor} !important`
        },
        [theme.breakpoints.up("md")]: {
            "&:span":{
                fontSize: "100%"
            },
            padding: "5px 10px !important",
        },
    }
}));

// Left Menu
export const LeftMenuStyle = makeStyles((theme) => ({
    drawerContainer:{
        height: "100%",
        position: "fixed",
        left: 0,
        top: 0,
        [theme.breakpoints.up("md")]: {
            position: "relative",
        },
        transition: "all 0.5s ease"
    },
    drawerOpen:{
        width: drawerWidth,
    },
    drawerClose:{
        width: "85px",
    },
    drawer: {
        width: "inherit",
        position: "inherit",
        paddingRight: "2px",
        height: "100%",
        overflow: "hidden"
    },
    drawerPaper: {
        position: "inherit !important",
        overflow: "inherit",
        width: "inherit",
        justifyContent: "center"
    },
    drawerWrap: {
        overflow: 'inherit',
        padding: "10px !important"
    },
    MenuTextTitle: {
        "& span":{
            fontWeight: "bold",
            display: "block",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "normal",
            textAlign: "left",
            wordWrap: "break-word",
            lineHeight: "1.2rem",
            maxHeight: "2.4rem",
            WebkitLineClamp: 2
            
        }
    },
    MenuIcon:{
        fontWeight: "bold",
        color: "black",
        fontSize: "35px !important"
    },
    subMenuIcon:{
        fontWeight: "bold",
        color: "#696969",
        fontSize: "25px !important"
    },
    nested: {
        paddingLeft: `${theme.spacing(2)}px !important`,
    },
    openDrawer: {
        top: "50%",
        right: "-17px",
        zIndex: 1201,
        position: "absolute",
        width: "30px",
        height: "30px",
        backgroundColor: "white",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    adminMenu:{
        padding: 10,
        display: "flex",
        flexWrap: "wrap",
        "& a":{
            margin: "0px 5px"
        }
    }
}));

// Footer
export const FooterStyle = makeStyles((theme) => ({
    footer: {
        clear: "both",
        minHeight : "70px",
        width: "calc(100vw - 40px)",
        borderTop: "2px solid #d8d8d8",
        background: "#ffffff",
        flexDirection: "column",
        ...flexCenter,
        position: "relative",
        [theme.breakpoints.up("md")]: {
            position: "absolute",
            bottom: 0
        },
    },
    infoWrap: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "flex-start",
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            alignItems: "center"
        },
    },
    footerLogo:{
        width: "5vw",
        height: "auto",
        padding: "5px",
        minWidth: "50px",
        marginRight: "10px"
    },
    info: {
        fontSize: "12px",
        fontWeight: "bold",
        borderLeft: "2px solid #c5c5c5"
    },
    copyright: {
        fontSize: "12px",
        color: "black",
        marginTop: "10px",
    }
}));

// Login
export const LoginStyle = makeStyles((theme) => ({
    loginContainer:{
        paddingTop: headerHeight,
        height: `calc(100vh - ${headerHeight + footerHeight}px)`,
        ...flexCenter
    },
    loginWrap: {
        width:"348px",
        height: "300px",
        ...flexCenter,
        flexDirection: "column"
    },
    loginTitle: {
        textAlign: "center",
        fontSize: "24px",
        fontWeight: "700",
        color: "#3e3e3e",
        width: "100%",
        borderBottom: "solid 2px #757575"
    },
    loginInputBox: {
        width: "100%",
        position: "relative"
    },
    loginInput: {
        '& label.Mui-focused': {
            color: KeyColor,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: KeyColor,
            },
        },
        width: "100%"
    },
    showPassword:{
        position: "absolute !important",
        height: "56px",
        right: 0
    },
    LoginButton: {
        width: "100%",
        height: "50px",
        padding: "0px !important",
        backgroundColor: "rgba(0,0,0,0) !important",
        borderRadius: "10px !important",
        '& .MuiButton-label': {
            backgroundColor: KeyColor2,
            height: "100%",
            borderRadius: "10px",
            color: "white",
            fontSize: "20px",
            fontWeight: "700"
        },
        '& :hover': {
            backgroundColor: KeyColor,
            boxShadow: 'none',
        },
    }
}));

export const HomeStyle = makeStyles((theme) => ({
    container:{
        paddingTop: headerHeight,
        height: "auto",
        ...flexCenter,
        [theme.breakpoints.up("md")]: {
            height: `calc(100vh - ${headerHeight + footerHeight}px)`
        },
    },
    content:{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        transition: "all 0.5s ease"
    },
    drawerOpen:{
        [theme.breakpoints.up("md")]: {
            width: `calc(100vw - ${drawerWidth}px)`,
            marginLeft: 0
        },
        width: "calc(100vw - 85px)",
        marginLeft: 85
    },
    drawerClose:{
        [theme.breakpoints.up("md")]: {
            marginLeft: 0
        },
        width: "calc(100vw - 85px)",
        marginLeft: 85
    },
    gaugeWrap: {
        flexDirection: "column",
        height: "auto",
        [theme.breakpoints.up("md")]: {
            height: "50%"
        },
        width: "inherit",
        ...flexCenter
    },
    title:{
        textAlign: "center",
        fontSize: "24px",
        fontWeight: "700",
        color: "#3e3e3e",
        width: "100%"
    },
    gaugeBox:{
        width: "100%",
        height: "600px",
        [theme.breakpoints.up("md")]: {
            height: "100%"
        },
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            alignItems: "center"
        },
    },
    gauge:{
        maxWidth: "500px",
        maxHeight: "225px",
        flexDirection: "column",
        ...flexCenter,
        "& svg": {
            width: "250px !important",
            height: "130px !important",
            [theme.breakpoints.up("md")]: {
                width: "400px !important",
                height: "225px !important"
            },
        }
    },
    gaugeLabel: {
        textAlign: "center",
        fontSize: "18px",
        fontWeight: "700",
        color: "#3e3e3e",
        width: "100%"
    }
}));

export const NewsIndexStyle = makeStyles((theme) => ({
    container:{
        paddingTop: headerHeight,
        height: "auto",
        ...flexCenter,
        [theme.breakpoints.up("md")]: {
            height: `calc(100vh - ${headerHeight + footerHeight}px)`
        },
    },
    content:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingTop: 20,
        height: "100%",
        transition: "all 0.5s ease",
        overflowY: "scroll"
    },
    drawerOpen:{
        [theme.breakpoints.up("md")]: {
            width: `calc(100vw - ${drawerWidth}px)`,
            marginLeft: 0
        },
        width: "calc(100vw - 85px)",
        marginLeft: 85
    },
    drawerClose:{
        [theme.breakpoints.up("md")]: {
            marginLeft: 0
        },
        width: "calc(100vw - 85px)",
        marginLeft: 85
    },
    chartButton: {
        width: "90%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    freqButtom:{
        background: theme.palette.common.white,
        fontWeight: "bold !important",
        border: `2px solid ${KeyColor} !important`,
        fontSize: "15px !important" ,
        padding: "3px 5px !important",
        "&:hover span":{
            transition: "all 0.3s ease",
            color: `${KeyColor} !important`
        },
        [theme.breakpoints.up("md")]: {
            "&:span":{
                fontSize: "100%"
            },
            padding: "5px 20px !important",
        },
    },
    chartWarp: {
        ...flexCenter,
        width: "100%",
        marginBottom: 20,
    },
    EWSWarp:{
        ...flexCenter,
        marginTop: 20,
        marginBottom: 30,
        height:300,
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
        },
        flexDirection: "column",
        width: "95%"
    },
    gaugeWrap: {
        ...flexCenter,
        flexDirection: "column",
        width: "60vw",
        maxWidth: "600px",
        maxHeight: "300px",
        [theme.breakpoints.up("md")]: {
            marginBottom:0
        },
        marginBottom: 20

    },
    koreaIndexGauge:{
        width: "100%",
        height: "100%",
        display:"flex", 
        justifyContent:"center", 
        alignItems:"center"
    },
    gaugeTitle:{
        fontSize: 20,
        fontWeight: "bold"
    },
    longDivider:{
        width: "100%",
        marginBottom: "20px !important"
    },
    shortDivider:{
        [theme.breakpoints.up("md")]: {
            display: "none"
        },
        display: "block",
        width: "100%",
        marginBottom: "20px !important"
    }
}));

export const StructedNewsIndexStyle = makeStyles((theme) => ({
    container:{
        paddingTop: headerHeight,
        height: "auto",
        display:"flex",
        justifyContent: "center",
        [theme.breakpoints.up("md")]: {
            height: `calc(100vh - ${headerHeight + footerHeight}px)`
        },
    },
    content:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingTop: 15,
        height: "100%",
        transition: "all 0.5s ease",
        paddingBottom: 200,
        overflowY: "scroll"
    },
    drawerOpen:{
        [theme.breakpoints.up("md")]: {
            width: `calc(100vw - ${drawerWidth}px)`,
            marginLeft: 0
        },
        width: "calc(100vw - 85px)",
        marginLeft: 85
    },
    drawerClose:{
        [theme.breakpoints.up("md")]: {
            marginLeft: 0
        },
        width: "calc(100vw - 85px)",
        marginLeft: 85
    },
    chartWarp: {
        ...flexCenter,
        flexDirection: "column",
        height: 500,
        width: "95%",
        marginBottom: 20
    },
    
}));

export const NewsIndexContentsStyle = makeStyles((theme) => ({
    indexContainer:{
        width: "80%",
        height: 300,
        border: "1px solid rgba(0, 0, 0, 0.3)",
        borderRadius: "10px",
        [theme.breakpoints.up("md")]: {
            marginBottom:0,
            padding: "15px 15px",
            flex: 1
        },
        marginBottom: 20,
        padding: "15px 10px"
    },
    indexTitle:{
        fontWeight: "bold",
        fontSize: "20px",
        paddingBottom: 10
    },
    indexWrap:{
        maxHeight: 260,
        overflowY: "scroll"
    },
    indexBox:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingTop: 12,
        "& div":{
            fontWeight: "bold",
            fontSize: "14px",
            paddingBottom: 5,
            paddingLeft: 5
        },
        "& span":{
            fontSize: "11px",
            paddingLeft: 10,
            marginBottom: 5
        }
    }
}));


export const KoreaNewsNetwork = makeStyles((theme) => ({
    container:{
        paddingTop: headerHeight,
        height: "auto",
        ...flexCenter,
        [theme.breakpoints.up("md")]: {
            height: `calc(100vh - ${headerHeight + footerHeight}px)`
        },
    },
    content:{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        transition: "all 0.5s ease",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            justifyContent: "space-around"
        },
    },
    drawerOpen:{
        [theme.breakpoints.up("md")]: {
            width: `calc(100vw - ${drawerWidth}px)`,
            marginLeft: 0
        },
        width: "calc(100vw - 85px)",
        marginLeft: 85
    },
    drawerClose:{
        [theme.breakpoints.up("md")]: {
            marginLeft: 0
        },
        width: "calc(100vw - 85px)",
        marginLeft: 85
    },
    radarWrap:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems:"center",
        width: "40%",
        height: "80%"
    },
    subplotWrap:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems:"center",
        width: "55%",
        height: "80%"
    },
    radarBox: {
        minHeight: "50vh",
        width: `calc(100vw - ${drawerWidth}px)`,
        padding: 10,
        // border: "3px solid rgba(0,0,0,0.2)",
        borderRadius: 20,
        ...flexCenter,
        [theme.breakpoints.up("md")]: {
            width: "100%",
            marginLeft: 0,
            padding: 10
        },
        "& g.apexcharts-xaxis": {
            "& text":{
                fill: "#505050",
                fontSize: 16,
                padding: 5
            }
        }
    },
    ListWarp:{
        marginBottom: "20px !important",
        width: "100%",
        border: "3px solid rgba(0,0,0,0.2)",
        padding: 10,
        borderRadius: 20,
    },
    List: {
        display: "flex",
        flexWrap: "wrap",
        height: "fit-content",
        maxHeight: "100px",
        width:"100%",
        overflowY: "scroll"
    },
    tab: {
        "& div.MuiTabs-scroller":{
            "& span.MuiTabs-indicator": {
                backgroundColor: "white !important"
            }
        }
    },
    tabBotton:{
        "& span": {
            color: "white",
            fontWeight: "bold"
        }
    },
}));

export const UsNewsNetwork = makeStyles((theme) => ({
    "span.MuiTabs-indicator":{
        backgroundColor: KeyColor
    },
    container:{
        paddingTop: headerHeight,
        height: "auto",
        ...flexCenter,
        [theme.breakpoints.up("md")]: {
            height: `calc(100vh - ${headerHeight + footerHeight}px)`
        },
    },
    content:{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        transition: "all 0.5s ease",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            justifyContent: "space-around"
        },
    },
    drawerOpen:{
        [theme.breakpoints.up("md")]: {
            width: `calc(100vw - ${drawerWidth}px)`,
            marginLeft: 0
        },
        width: "calc(100vw - 85px)",
        marginLeft: 85
    },
    drawerClose:{
        [theme.breakpoints.up("md")]: {
            marginLeft: 0
        },
        width: "calc(100vw - 85px)",
        marginLeft: 85
    },
    savePng: {
        "& span":{
            fontSize: 12,
            color: KeyColor
        }
    },
    sunburstBox:{
        height: "67vw",
        width: "80vw",
        [theme.breakpoints.up("md")]: {
            height: "70vh",
            width: "84vh"
        },
    },
    NewsWrap: {
        height: "80%",
        width: "calc(100% - 100vh)",
        border: "3px solid rgba(0,0,0,0.2)",
        borderRadius: 20,
        padding: 30,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    TitleBox:{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    selectField:{
        ...flexCenter
    },
    formCtrl:{
        minWidth: "100px !important",
        margin: "10px !important"
    },
    labelBox:{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    label:{
        margin: "3px !important",
        padding: "1px 10px !important",
        height: 30,
        "& span":{
            fontWeight: "bold"
        }
    },
    labelSelected:{
        backgroundColor: "#9C9C9C !important",
        "& span": {
            color: "white !important"
        }
    },
    all:{
        border: "2px solid #D4D4D4 !important",
        "& span":{
            color: "#D4D4D4"
        }
    },
    positive:{
        border: "2px solid #132A6D !important",
        "& span":{
            color: "#132A6D"
        }
    },
    negative:{
        border: "2px solid #FA0000 !important",
        "& span":{
            color: "#FA0000"
        }
    },
    uncertainty:{
        border: "2px solid #FFB618 !important",
        "& span":{
            color: "#FFB618"
        }
    },
    NewWrapTitle:{
        fontSize: 20,
        marginBottom: 10
    },
    News: {
        marginTop: "10px !important",
        height: "calc(100% - 120px)",
        overflowY: "scroll"
    },
    NewsLoading: {
        marginTop: "10px !important",
        height: "calc(100% - 120px)",
        overflowY: "scroll",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    NewsInfo:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start"
    },
    newsTitle:{
        color: "#232323",
        width: "100%",
        overflow: "hidden",
        wordBreak: "break-all",
        fontWeight: 600,
        "& strong": {
            color: KeyColor
        }
    },
    newsContent:{
        color: "#808080",
        width: "100%",
        overflow: "hidden",
        wordBreak: "break-all",
        "& strong": {
            color: KeyColor2
        }
    },
    newsSite: {
        color: "#CDCDCD",
        marginTop:10,
        fontSize: 12
    }
}));

export const PortfolioRiskIndexStyle = makeStyles((theme) => ({
    container:{
        paddingTop: headerHeight,
        height: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        [theme.breakpoints.up("md")]: {
            height: `calc(100vh - ${headerHeight + footerHeight}px)`
        },
    },
    content:{
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        transition: "all 0.5s ease",
        height: "calc(100vh - 180px)",
        overflowY: "scroll"
    },
    drawerOpen:{
        [theme.breakpoints.up("md")]: {
            width: `calc(100vw - ${drawerWidth}px)`,
            marginLeft: 0
        },
        width: "calc(100vw - 85px)",
        marginLeft: 85
    },
    drawerClose:{
        [theme.breakpoints.up("md")]: {
            marginLeft: 0
        },
        width: "calc(100vw - 85px)",
        marginLeft: 85
    },
    banchmarkBox: {
        marginTop: 20,
        width: "90%",
        padding: "10px 30px",
        "& h1": {
            fontSize: 20,
            backgroundImage: `url(${quantit_symbol})`,
            backgroundSize: "31px",
            backgroundRepeat: "no-repeat",
            paddingLeft: 40
        }
    },
    banchmark: {
        backgroundColor: `${KeyColor3}50`,
        padding: "10px 20px",
        marginTop: 10,
        borderRadius: 15,
        display: "flex",
        flexWrap: "wrap"
    },
    banchmarkItem: {
        display: "flex",
        flexDirection: "column",
        width: 180, 
        margin: "0px 10px",
        fontWeight: "bold"
    },
    returns:{
        display: "flex",
        width: 180,
        margin: "0px 10px",
        fontWeight: "bold",
        alignItems: "center"
    },
    banchmarkControl:{
        display: "flex",
        alignItems: "center"
    },
    protfolio: {
        marginTop: 10,
        width: "90%",
        padding: "10px 30px",
        "& h1": {
            fontSize: 20,
            backgroundImage: `url(${quantit_symbol})`,
            backgroundSize: "31px",
            backgroundRepeat: "no-repeat",
            paddingLeft: 40
        },
        "& .MuiFormGroup-row": {
            backgroundColor: `${KeyColor3}50`,
            padding: "10px 20px",
            marginTop: 10,
            borderRadius: 15,
            "& .MuiInputBase-root":{
                marginLeft: 10
            }
        }
    },
    chartWarp: {
        width: "90%"
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    button:{
        background: theme.palette.common.white,
        fontWeight: "bold !important",
        border: `2px solid ${KeyColor} !important`,
        fontSize: "13px !important" ,
        padding: "3px 5px !important",
        "&:hover span":{
            transition: "all 0.3s ease",
            color: `${KeyColor} !important`
        },
        [theme.breakpoints.up("md")]: {
            "&:span":{
                fontSize: "100%"
            },
            padding: "2px 10px !important",
        },
    }
}));

export const AssetClassRiskIndexStyle = makeStyles((theme) => ({
    container:{
        paddingTop: headerHeight,
        height: "auto",
        ...flexCenter,
        [theme.breakpoints.up("md")]: {
            height: `calc(100vh - ${headerHeight + footerHeight}px)`
        },
    },
    content:{
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        transition: "all 0.5s ease",
        height: "calc(100vh - 180px)",
        overflowY: "scroll"
    },
    drawerOpen:{
        [theme.breakpoints.up("md")]: {
            width: `calc(100vw - ${drawerWidth}px)`,
            marginLeft: 0
        },
        width: "calc(100vw - 85px)",
        marginLeft: 85
    },
    drawerClose:{
        [theme.breakpoints.up("md")]: {
            marginLeft: 0
        },
        width: "calc(100vw - 85px)",
        marginLeft: 85
    },
    banchmarkBox: {
        marginTop: 20,
        width: "90%",
        padding: "10px 30px",
        "& h1": {
            fontSize: 20,
            backgroundImage: `url(${quantit_symbol})`,
            backgroundSize: "31px",
            backgroundRepeat: "no-repeat",
            paddingLeft: 40
        }
    },
    banchmark: {
        backgroundColor: `${KeyColor3}50`,
        padding: "10px 20px",
        marginTop: 10,
        borderRadius: 15,
        display: "flex",
        flexWrap: "wrap"
    },
    banchmarkItem: {
        display: "flex",
        flexDirection: "column",
        width: 180, 
        margin: "0px 10px",
        fontWeight: "bold"
    },
    banchmarkControl:{
        display: "flex",
        alignItems: "center"
    },
    chartWarp: {
        width: "90%"
    }
}));

export const AdminStyle = makeStyles((theme) => ({
    container:{
        paddingTop: headerHeight,
        height: "auto",
        ...flexCenter,
        [theme.breakpoints.up("md")]: {
            height: `calc(100vh - ${headerHeight + footerHeight}px)`
        },
    },
    content:{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        transition: "all 0.5s ease"
    },
    drawerOpen:{
        [theme.breakpoints.up("md")]: {
            width: `calc(100vw - ${drawerWidth}px)`,
            marginLeft: 0
        },
        width: "calc(100vw - 85px)",
        marginLeft: 85
    },
    drawerClose:{
        [theme.breakpoints.up("md")]: {
            marginLeft: 0
        },
        width: "calc(100vw - 85px)",
        marginLeft: 85
    }
}));

export const SaveStyle = makeStyles((theme) => ({
    container: {
        "& .MuiDialog-paper":{
            padding: 20
        }
    },
    nameInput: {
        '& label.Mui-focused': {
            color: KeyColor,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: KeyColor,
            },
        },
        width: "100%"
    },
    button:{
        background: theme.palette.common.white,
        fontWeight: "bold !important",
        border: `2px solid ${KeyColor} !important`,
        fontSize: "15px !important" ,
        padding: "3px 5px !important",
        "&:hover span":{
            transition: "all 0.3s ease",
            color: `${KeyColor} !important`
        },
        [theme.breakpoints.up("md")]: {
            "&:span":{
                fontSize: "100%"
            },
            padding: "5px 20px !important",
        },
    }
}))

export const ListStyle = makeStyles((theme) => ({
    container: {
        "& .MuiDialog-paper":{
            padding: 20,
            maxWidth: "none"
        }
    },
    nameInput: {
        '& label.Mui-focused': {
            color: KeyColor,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: KeyColor,
            },
        },
        width: "100%"
    },
    select:{
        background: theme.palette.common.white,
        fontWeight: "bold !important",
        border: `2px solid ${KeyColor} !important`,
        fontSize: "15px !important" ,
        padding: "3px 5px !important",
        "&:hover span":{
            transition: "all 0.3s ease",
            color: `${KeyColor} !important`
        },
        [theme.breakpoints.up("md")]: {
            "&:span":{
                fontSize: "100%"
            },
            padding: "2px 5px !important",
        },
    },
    delete: {
        background: theme.palette.common.white,
        fontWeight: "bold !important",
        border: `2px solid #ff0000 !important`,
        fontSize: "15px !important" ,
        padding: "3px 5px !important",
        "&:hover span":{
            transition: "all 0.3s ease",
            color: `#ff0000 !important`
        },
        [theme.breakpoints.up("md")]: {
            "&:span":{
                fontSize: "100%"
            },
            padding: "2px 5px !important",
        },
    },
}))

export const UploadPageStyle = makeStyles((theme) => ({
    container:{
        paddingTop: headerHeight,
        height: "auto",
        ...flexCenter,
        [theme.breakpoints.up("md")]: {
            height: `calc(100vh - ${headerHeight + footerHeight}px)`
        },
    },
    content:{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        transition: "all 0.5s ease"
    },
    drawerOpen:{
        [theme.breakpoints.up("md")]: {
            width: `calc(100vw - ${drawerWidth}px)`,
            marginLeft: 0
        },
        width: "calc(100vw - 85px)",
        marginLeft: 85
    },
    drawerClose:{
        [theme.breakpoints.up("md")]: {
            marginLeft: 0
        },
        width: "calc(100vw - 85px)",
        marginLeft: 85
    },
    tab: {
        "& div.MuiTabs-scroller":{
            "& span.MuiTabs-indicator": {
                backgroundColor: "white !important"
            }
        }
    },
    tabBotton:{
        "& span": {
            color: "white",
            fontWeight: "bold"
        }
    },
    formatBox: {
        marginTop: 20,
        width: "90%",
        "& h1": {
            fontSize: 20,
            backgroundImage: `url(${quantit_symbol})`,
            backgroundSize: "31px",
            backgroundRepeat: "no-repeat",
            paddingLeft: 40
        }
    },
    formatList: {
        border: `2px solid ${KeyColor3}`,
        padding: "0px 10px",
        marginTop: 10,
        borderRadius: 15,
        display: "flex",
        flexWrap: "wrap"
    },
    select:{
        background: theme.palette.common.white,
        fontWeight: "bold !important",
        border: `2px solid ${KeyColor} !important`,
        fontSize: "13px !important" ,
        padding: "3px 5px !important",
        "&:hover span":{
            transition: "all 0.3s ease",
            color: `${KeyColor} !important`
        },
        [theme.breakpoints.up("md")]: {
            "&:span":{
                fontSize: "100%"
            },
            padding: "2px 5px !important",
        },
    },
    delete: {
        background: theme.palette.common.white,
        fontWeight: "bold !important",
        border: `2px solid #ff0000 !important`,
        fontSize: "13px !important" ,
        padding: "3px 5px !important",
        "&:hover span":{
            transition: "all 0.3s ease",
            color: `#ff0000 !important`
        },
        [theme.breakpoints.up("md")]: {
            "&:span":{
                fontSize: "100%"
            },
            padding: "2px 5px !important",
        },
    },
}));
