import { gql } from '@apollo/client';

export default class Mutations {
    static getToken = gql`
    mutation getToken($email:String!, $password:String!){
        tokenAuth(email:$email, password:$password){
            payload
            token
            refreshExpiresIn
        }
    }`

    static verifyToken = gql`
    mutation verifyToken($token:String!){
        verifyToken(token:$token){
          payload
        }
    }`
}