import moment from 'moment';
import React from 'react';
import ReactApexCharts from 'react-apexcharts'
import { MaxIndex, MinIndex } from 'utils/chartNum';
import { dateFormats } from '../../../utils/constants';

const IndexLineChart = (props) => {
    const { name, data, setTarget, viewType } = props
    const min = MinIndex(data.index) === -Infinity ? 0 : MinIndex(data.index)
    const max = MaxIndex(data.index) === -Infinity ? 0 : MaxIndex(data.index)

    // 미래 예측값 표시를 위한 점선 영역 계산 로직
    const dateFormat = viewType === 'MONTH' ? dateFormats.dash_month : dateFormats.dash_day;
    const now = viewType === 'MONTH' ? moment().format(dateFormat) : moment().subtract('7', 'd').format(dateFormat);
    const lastDate = moment(data.date[data.date.length-1]).format(dateFormat);
    const dashedCount = (lastDate < now) ? 0 : 1
    
    const series = [
      {
        name: name,
        data: data.index
      },
      {
        name: `${name} EWS`,
        data: data.ews
      }
    ]
    
    const annotations = data.annotations.map(annotation => {
      return {
        ...annotation,
        fillColor: '#FEEAEC',
        opacity: 0.4,
        borderColor: '#FEEAEC',
        strokeDashArray: 1
      }
    });

    const chartOptions = {
      chart: {
        height: 300,
        type: 'line',
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        animations: {
          enabled: true
        },
        toolbar: {
          show: false
        },
        events: {
          click: function(event, chartContext, config) {
            if (data.date[config.dataPointIndex] !== undefined){
              setTarget(data.date[config.dataPointIndex]);
            }
          }
        }
      },
      legend: {
        show: false
      },
      forecastDataPoints: {
        count: dashedCount,
        fillOpacity: 1,
        strokeWidth: 2,
        dashArray: 4,
      },
      annotations: {
        position: 'back',
        xaxis: annotations
      },
      tooltip: {
        style:{
          fontSize: '9px',
          fontWeight: 400,
          lineHeight: '12px'
        },
        marker: {
          show: false
        },
        custom: function({series, seriesIndex, dataPointIndex, w}) {
          const tooltipHtml = [];

          tooltipHtml.push('<div class="tooltipBox">');
          tooltipHtml.push(`<span class="date">${data.date[dataPointIndex]}</span>`);
          tooltipHtml.push('<div class="divider" />');
          tooltipHtml.push('<div class="item">');
          tooltipHtml.push(`<span class="title">${name}:</span>`);
          tooltipHtml.push(`<span class="value">${series[0][dataPointIndex]}</span>`);
          tooltipHtml.push('</div>');
          tooltipHtml.push('<div class="item">');
          tooltipHtml.push(`<span class="title">${name} EWS:</span>`);
          tooltipHtml.push(`<span class="value">${series[1][dataPointIndex]}</span>`);
          tooltipHtml.push('</div>');
          tooltipHtml.push('</div>');

          return tooltipHtml.join('');
        }
      },
      colors: ['#35363E', '#F42C3F'],
      stroke: {
        width: [2, 2],
        curve: 'straight',
        dashArray: [0, 0]
      },
      xaxis: {
        categories: data.date,
        tickAmount: 20,
        labels: {
          rotateAlways: true
        },
        tooltip: {
          enabled: false
        },
        crosshairs: {
          show: true,
          position: 'back',
          stroke: {
              color: '#79797E',
              width: 0.5,
              dashArray: 2,
          },
        },
      },
      yaxis: [
        {
          seriesName: `${name}`,
          showAlways: true,
          min: min,
          max: max,
          axisBorder: {
            show: true,
            color: '#1F2028'
          },
          labels: {
            style: {
              fontSize: '9px',
              fontWeight: 400,
              colors: '#1F2028',
              align: 'right'
            }
          },
          title: {
            text: `${name}`,
            rotate: 0,
            offsetX: 32,
            offsetY: -200,
            style: {
              color: '#1F2028',
            }
          },
        },
        {
          seriesName: `${name} EWS`,
          showAlways: true,
          min: -1,
          max: 1,
          opposite: true,
          axisBorder: {
            show: true,
            color: '#F42C3F'
          },
          labels: {
            style: {
              colors: '#F42C3F',
              align: 'right',
              fontSize: '9px',
              fontWeight: 400,
            }
          },
          title: {
            text: `${name} EWS`,
            rotate: 0,
            offsetX: -48,
            offsetY: -200,
            style: {
              color: '#F42C3F',
            }
          },
        }
      ]
    }

    return (
      <div style={{width: "100%", height: 480}}>
        <ReactApexCharts series={series} options={chartOptions} type="line" height="100%"/>
      </div>
    );
};

export default IndexLineChart;