import React, { useEffect } from 'react';
import lottie from 'lottie-web';
import animationData from '../../lotties/loading.json'

const ActivityIndicator = (props) => {
  const { width='128px', height='128px', wrapperId='activityIndicatorWrap', position } = props;
  const container = document.querySelector(`#${wrapperId}`);

  const styleDefault = {
    zIndex: 100,
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    width: '100%', 
    height: '100%',
  }

  let styleObject = styleDefault;
  
  if(position) {
    styleObject['position'] = position;
  }

  useEffect(() => {
    lottie.loadAnimation({
      container: container,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
    })
  }, [container]);

	return (
		<div style={styleObject}>
      <div id={`${wrapperId}`} style={{width: `${width}`, height: `${height}`}} />
		</div>
	)
}

export default ActivityIndicator;