import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { useMutation } from "@apollo/client";
import Mutations from "../../graphql/mutation";
import { usePopupManager } from "react-popup-manager";
import LayerPopup from "./LayerPopup";

const NavigationBar = (props) => {
  const { history, location } = props;

  const [folded, setFolded] = useState(false);
  const [korExpanded, setKorExpanded] = useState(true);
  const [usExpanded, setUsExpanded] = useState(true);
  const [devExpanded, setDevExpanded] = useState(true);
  const popupManager = usePopupManager();

  const finterToken = window.localStorage.getItem("finterToken");

  const [tokenVerify] = useMutation(Mutations.verifyToken);
  useEffect(() => {
    if (window.localStorage.getItem("finterToken") !== null) {
      console.log("finter login");
      return;
    }

    if (window.localStorage.getItem("jwt") !== null) {
      tokenVerify({
        variables: { token: window.localStorage.getItem("jwt") },
      }).catch((error) => {
        // toast.warn("인증이 만료되어 재로그인 합니다.")
        window.localStorage.clear();
        history.push("/login");
      });
      return;
    }
    window.localStorage.clear();
    history.push("/login");
  }, [history, tokenVerify]);

  const signout = (e) => {
    popupManager.open(LayerPopup, {
      title: "로그아웃 되었습니다.",
    });
    e.preventDefault();
    window.localStorage.clear();
    history.replace("/login", "urlhistory");
  };

  return (
    <div
      className={`navigation ${folded ? "fold" : ""}`}
      onClick={() => {
        if (folded) setFolded(false);
      }}
    >
      <div className="naviTop">
        <Link to="/home">
          <h1 className="logo">퀸팃</h1>
        </Link>
        <button
          type="button"
          className="btnFold"
          onClick={() => {
            setFolded(true);
          }}
        >
          접기
        </button>
      </div>
      <div className="navi">
        <ul>
          <li>
            <Link to="/home" className="home">
              <span
                className={`menu ${"/home" === location.pathname ? "on" : ""}`}
              >
                Home
              </span>
            </Link>
          </li>
          <li className={`${korExpanded ? "active" : ""}`}>
            <button
              type="button"
              className="dep ic1"
              onClick={() => {
                setKorExpanded(!korExpanded);
              }}
            >
              <span
                className={`menu ${
                  location.pathname.startsWith("/korea") ? "on" : ""
                }`}
              >
                KOR News
              </span>
            </button>
            {!folded && (
              <div className="subMenuWrap">
                <ul>
                  <li key="kni">
                    <Link to="/korea/ni">
                      <span
                        className={`subMenu ${
                          "/korea/ni" === location.pathname ? "on" : ""
                        }`}
                      >
                        Korea News Index
                      </span>
                    </Link>
                  </li>
                  <li key="knn">
                    <Link to="/korea/nn">
                      <span
                        className={`subMenu ${
                          "/korea/nn" === location.pathname ? "on" : ""
                        }`}
                      >
                        Korea News Network
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </li>
          <li className={`${usExpanded ? "active" : ""}`}>
            <button
              type="button"
              className="dep ic2"
              onClick={() => {
                setUsExpanded(!usExpanded);
              }}
            >
              <span
                className={`menu ${
                  location.pathname.startsWith("/us") ? "on" : ""
                }`}
              >
                US News
              </span>
            </button>
            {!folded && (
              <div className="subMenuWrap">
                <ul>
                  <li key="eni">
                    <Link to="/us/ni">
                      <span
                        className={`subMenu ${
                          "/us/ni" === location.pathname ? "on" : ""
                        }`}
                      >
                        US News Index
                      </span>
                    </Link>
                  </li>
                  <li key="ennm">
                    <Link to="/us/nnm">
                      <span
                        className={`subMenu ${
                          "/us/nnm" === location.pathname ? "on" : ""
                        }`}
                      >
                        US News Network (Monthly)
                      </span>
                    </Link>
                  </li>
                  <li key="ennw">
                    <Link to="/us/nnw">
                      <span
                        className={`subMenu ${
                          "/us/nnw" === location.pathname ? "on" : ""
                        }`}
                      >
                        US News Network (Weekly)
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </li>
          <li className={`${devExpanded ? "active" : ""}`}>
            <button
              type="button"
              className="dep ic3"
              onClick={() => {
                setDevExpanded(!devExpanded);
              }}
            >
              <span
                className={`menu ${
                  location.pathname.startsWith("/dev") ? "on" : ""
                }`}
              >
                Developer
              </span>
            </button>
            <div className="subMenuWrap">
              <ul>
                <li>
                  <a href="https://api.ews.quantit.io/graphql" target="blank">
                    <span>API</span>
                  </a>
                </li>
                <li>
                  <a href="https://api.ews.quantit.io/admin" target="blank">
                    <span>Account</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      {!finterToken && (
        <div className="naviBottom">
          <a href="#none">
            <span>System</span>
          </a>
          <button type="button" className="bntLogOut" onClick={signout}>
            <span className="menu">Logout Account</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default withRouter(NavigationBar);
