import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { useMutation } from "@apollo/client";
import Mutations from "../graphql/mutation";
import { usePopupManager } from "react-popup-manager";
import LayerPopup from "../components/common/LayerPopup";

const LoginPage = (props) => {
  const { history } = props;
  const popupManager = usePopupManager();

  const [tokenVerify] = useMutation(Mutations.verifyToken);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // token 있을때 home으로 라우팅
  useEffect(() => {
    if (window.localStorage.getItem("finterToken") !== null) {
      history.replace("/home");
      return;
    } else if (window.localStorage.getItem("jwt") !== null) {
      tokenVerify({ variables: { token: window.localStorage.getItem("jwt") } })
        .then((data) => {
          history.replace("/home");
        })
        .catch((error) => {
          window.localStorage.clear();
        });
    }
    else{
      window.localStorage.clear();
    }
  }, [history, tokenVerify]);

  // Login 관련 함수
  const [getJwt] = useMutation(Mutations.getToken);

  const login = async () => {
    // console.log(`email:${email}, password:${password}`)
    if (email.length < 1) {
      popupManager.open(LayerPopup, {
        title: "ID를 입력해주세요",
      });
      return false;
    }

    if (password.length < 1) {
      popupManager.open(LayerPopup, {
        title: "비밀번호를 입력해주세요",
      });
      return false;
    }

    let token;

    await getJwt({ variables: { email: email, password: password } })
      .then((data) => {
        token = data.data.tokenAuth.token;
        tokenVerify({ variables: { token: token } }).then((data) => {
          const verifyToken = data.data.verifyToken;
          const now = moment();
          const nowString = now.format("YYY-MM-DDTHH:mm:SS");
          if (verifyToken) {
            window.localStorage.setItem("jwt", token);
            window.localStorage.setItem("email", verifyToken.payload.email);
            window.localStorage.setItem("exp", verifyToken.payload.exp);
            window.localStorage.setItem("logintime", nowString);
            history.push("/home");
          }
        });
      })
      .catch((err) => {
        popupManager.open(LayerPopup, {
          title: "로그인 실패",
          message: [
            "ID 혹은 비밀번호가 일치하지 않습니다.",
            "다시 시도해 주세요.",
          ],
          height: 240,
        });
      });
  };

  return (
    <div className="container">
      <div className="loginWrap">
        <div className="ewsInfo">
          <h1>
            <strong>EWS</strong>
            <span>Early Warning System</span>
          </h1>
          <p>뉴스 자연어 처리 기반 금융위험 조기경보 시스템</p>
          <ul>
            <li>
              위험예측에 따른
              <br />
              포트폴리오 설계 자료로 활용
            </li>
            <li>
              VIX, DXY와 같이 금융시장위험을 <br />
              탐지하는 보조 지표로 활용
            </li>
            <li>
              새로운 수요탐지를 통한 <br />
              투자기회 및 수익 발굴
            </li>
          </ul>
        </div>
        <div className="logConts">
          <h2>Quantit</h2>
          <div className="logBox">
            <label htmlFor="email">ID</label>
            <span>
              <input
                type="text"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </span>
            <label htmlFor="password">Password</label>
            <span>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") login();
                }}
              />
            </span>
          </div>
          <button type="button" className="btnLog" onClick={login}>
            <span>로그인</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(LoginPage);
