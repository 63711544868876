import React from 'react';
import ReactApexCharts from 'react-apexcharts'

const KoreaRadarChart = (props) => {
    const { data, index, legendList, width='100%', height='700px' } = props;
    
    const chartOptions = {
      chart: {
        type: 'radar',
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1
        },
        toolbar: {
          show: false
        },
        offsetY: -1
      },
      tooltip:{
        enabled: false
      },
      legend: {
        show: false
      },
      stroke: {
        width: 2
      },
      xaxis: {
        type: 'category',
        categories: index,
        labels:{
          show: false,
        }
      },
      yaxis: {
        type: "numeric",
        min: -100,
        max: 100,
        tickAmount: 4,
        labels: {
          offsetY: 6
        },
      }
    }

    return (
      <div className='radarChartWrap'>
        <ReactApexCharts className='radarChart' series={data} options={chartOptions} type="radar" width={width} height={height} />
        <div className='labelWrap'>
          <span className='tag gr economy'>
            <em>경제</em>경기
          </span>
          <span className='tag gr financialMarket'>
            <em>경제</em>금융시장
          </span>
          <span className='tag gr economyPolicy'>
            <em>경제</em>정책
          </span>
          <span className='tag pu business'>
            <em>기업</em>사업내용
          </span>
          <span className='tag pu management'>
            <em>기업</em>경영내용
          </span>
          <span className='tag pu profitLoss'>
            <em>기업</em>손익
          </span>
          <span className='tag pu financialPosition'>
            <em>기업</em>재무상태
          </span>
          <span className='tag pu companyNA'>
            <em>기업</em>그 외
          </span>
          <span className='tag or overseasEconomy'>
            <em>해외</em>경기
          </span>
          <span className='tag or overseasMarket'>
            <em>해외</em>금융시장
          </span>
          <span className='tag or overseasPolicy'>
            <em>해외</em>정책
          </span>
          <span className='tag or overseasNA'>
            <em>해외</em>그 외
          </span>
          <span className='tag gy etc'>
            <em>etc</em>기타
          </span>
          <span className='tag gy etcNA'>
            <em>etc</em>그 외
          </span>
        </div>
        <div className='legendWrap'>
        {legendList.map((l, index) => {
          return (
            <span key={index} className={`color_${index}`}>{l}</span>
          )
        })}
        </div>
      </div>
    );
};

export default KoreaRadarChart;